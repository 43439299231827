<template>
  <div v-loading="loading3">
    <el-menu
      :default-active="activeIndex2"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-menu-item index="1">Search List</el-menu-item>
      <el-menu-item index="2">All users</el-menu-item>
      <el-menu-item index="3"> Invitation inquiry</el-menu-item>
      <el-menu-item index="4"> Record export</el-menu-item>
      <!-- <el-submenu index="2">
                 <template slot="title">我的工作台</template>
<el-menu-item index="2-1">选项1</el-menu-item>
<el-menu-item index="2-2">选项2</el-menu-item>
<el-menu-item index="2-3">选项3</el-menu-item>
<el-submenu index="2-4">
    <template slot="title">选项4</template>
    <el-menu-item index="2-4-1">选项1</el-menu-item>
    <el-menu-item index="2-4-2">选项2</el-menu-item>
    <el-menu-item index="2-4-3">选项3</el-menu-item>
</el-submenu>
</el-submenu>-->
      <el-button class="out" @click="out">Sign out</el-button>
    </el-menu>
    <div v-if="activeIndex2 == 1">
      <div class="checkinput">
        <el-date-picker
          v-model="value1"
          style="margin-right: 10px"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start time"
          @change="getdata()"
          end-placeholder="End time"
        >
        </el-date-picker>
        <el-input
          style="width: 200px; margin-right: 10px"
          placeholder="Please enter the address"
          v-model="address"
        ></el-input>
        <el-input
          style="width: 230px; margin-right: 10px"
          placeholder="Please enter the invitation code"
          v-model="code"
        ></el-input>
        <el-input
          style="width: 200px; margin-right: 10px"
          placeholder="Invite by"
          v-model="invite_by"
        ></el-input>
        <el-button @click="searh">Search</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="user_id" label="UserId">
          <template #default="{ row }">
            <span style="cursor: pointer" @click="copyfun(row.user_id)">{{
              row.user_id
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="Address">
          <template #default="{ row }">
            <span style="cursor: pointer" @click="copyfun(row.address)">{{
              row.address
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="evm_address" label="EVM Address">
          <template #default="{ row }">
            <span style="cursor: pointer" @click="copyfun(row.evm_address)">{{
              row.evm_address
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="bonus_time" label="BonusTime"> </el-table-column> -->
        <el-table-column prop="peosonal_node" label="Peosonal Node">
        </el-table-column>
        <el-table-column prop="direct_node" label="Direct Node">
        </el-table-column>
        <el-table-column prop="team_node" label="Team Node"> </el-table-column>
        <el-table-column prop="code" label="Code" width="100">
          <template #default="{ row }">
            <span style="cursor: pointer" @click="copyfun(row.code)">{{
              row.code
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="bonus" label="Bonus" width="100" sortable> -->
        <!-- </el-table-column> -->
        <el-table-column prop="direct_count" label="DirectCount" sortable>
        </el-table-column>
        <!-- <el-table-column
          prop="direct_sol_amount"
          label="DirectSolAmount"
          width="200"
          sortable
        >
        </el-table-column> -->
        <el-table-column prop="invite_by" label="InviteBy">
          <template slot="header" slot-scope="scope">
            InviteBy
            <el-checkbox
              v-model="is_inviteby"
              @change="tosearch()"
            ></el-checkbox>
          </template>
          <template slot-scope="scope">
            <span
              style="cursor: pointer"
              @click="copyfun(scope.row.invite_by)"
              >{{ scope.row.invite_by }}</span
            >
          </template>
        </el-table-column>
        <!-- <el-table-column prop="sol_amount" label="SolAmount" sortable>
        </el-table-column> -->
        <el-table-column prop="team_count" label="TeamCount" sortable>
        </el-table-column>
        <!-- <el-table-column prop="team_sol_amount" label="TeamSolAmount" sortable>
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="nowpage"
          :page-size="pagesize"
          layout="total, prev, pager, next"
          :total="allpage"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="activeIndex2 == 2">
      <el-table
        :data="tableData2"
        style="width: 100%; margin-top: 40px"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="regis_count" label="Register count">
        </el-table-column>
        <el-table-column prop="bonus_count" label="Bonus count">
        </el-table-column>
        <el-table-column prop="totol_amount" label="Totol amount">
        </el-table-column>
      </el-table>
    </div>
    <div v-if="activeIndex2 == 3">
      <div class="checkinput">
        <el-input
          style="width: 200px; margin-right: 10px"
          placeholder="Please enter the code"
          v-model="code2"
        ></el-input>
        <el-button @click="searh2">Search</el-button>
      </div>
      <el-table
        :data="tableData3"
        style="width: 100%; margin-top: 40px"
        v-loading="loading2"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="count" label="Count"> </el-table-column>
      </el-table>
    </div>
    <div v-if="activeIndex2 == 4">
      <div class="checkinput">
        <el-date-picker
          v-model="value2"
          style="margin-right: 10px"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start time"
          @change="getdata2()"
          end-placeholder="End time"
        >
        </el-date-picker>
        <el-input
          style="width: 200px; margin-right: 10px"
          placeholder="Please enter the address"
          v-model="address2"
        ></el-input>
        <el-button @click="search3">Export</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { Search, getallCount, getbycode, postExportrecord } from "@/api/index";
import { getCode, getToken, removeToken } from "@/utils/auth";
export default {
  name: "Index",
  data() {
    return {
      loading: false,
      loading2: false,
      loading3: false,
      address: "",
      code: "",
      code2: "",
      invite_by: "",
      start_time: "",
      end_time: "",
      address2: "",
      start_time2: "",
      end_time2: "",
      is_inviteby: false,
      value1: "",
      value2: "",
      activeIndex2: "1",
      tableData: [],
      pagesize: 10,
      allpage: 0,
      nowpage: 0,
      tableData2: [],
      tableData3: [],
      count: 0,
    };
  },
  created() {
    if (getToken() == undefined) {
      this.$router.push("/login");
    } else {
      this.tosearch();
      getallCount().then((res) => {
        console.log(res);
        this.tableData2 = [res.data];
      });
    }
  },
  methods: {
    getdata() {
      if (this.value1 == null) {
        this.start_time = "";
        this.end_time = "";
      } else {
        this.start_time = this.value1[0].toLocaleString().replace(/\//gi, "-");
        this.end_time = this.value1[1].toLocaleString().replace(/\//gi, "-");
      }
    },
    getdata2() {
      if (this.value2 == null) {
        this.start_time2 = "";
        this.end_time2 = "";
      } else {
        this.start_time2 = this.value2[0].toLocaleString().replace(/\//gi, "-");
        this.end_time2 = this.value2[1].toLocaleString().replace(/\//gi, "-");
      }
    },
    search3() {
      // if (this.address2 == '' || this.value2 == null) {
      //     return
      // }
      this.loading3 = true;
      postExportrecord({
        address: this.address2,
        start_time: this.start_time2,
        end_time: this.end_time2,
      }).then((res) => {
        var fileName = "TransferRecord";
        const blob = new Blob([res], { type: "text/csv;charset=utf-8;" }); // 创建Blob对象
        const url = URL.createObjectURL(blob); // 转换为URL
        const link = document.createElement("a"); // 创建<a>元素
        link.setAttribute("href", url);
        link.setAttribute("download", `${fileName}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click(); // 触发下载
        document.body.removeChild(link); // 清理
        this.loading3 = false;
      });
    },
    tosearch() {
      this.loading = true;
      Search({
        address: this.address,
        code: this.code,
        invite_by: this.invite_by,
        page: this.nowpage,
        page_size: 10,
        start_time: this.start_time,
        end_time: this.end_time,
        is_inviteby: this.is_inviteby,
      })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.ReturnData;
          this.allpage = res.data.Count;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    handleSelect(key, keyPath) {
      this.activeIndex2 = key;
      console.log(key, keyPath);
    },
    searh() {
      this.tosearch();
    },
    searh2() {
      if (this.code2 == "") {
        return;
      }
      this.loading2 = true;
      getbycode(this.code2)
        .then((res) => {
          this.loading2 = false;
          this.tableData3 = [res.data];
        })
        .catch((res) => {
          this.loading2 = false;
        });
    },
    handleSizeChange(val) {
      console.log(1111);
    },
    handleCurrentChange(val) {
      this.loading = true;
      this.tosearch();
    },
    out() {
      removeToken();
      this.$router.push("/login");
    },
    async copyfun(text) {
      try {
        await navigator.clipboard.writeText(text);
        Message({
          message: "Success",
          type: "success",
          duration: 3 * 1000,
        });
        // 可以在这里添加复制成功的提示
      } catch (err) {
        Message({
          message: "error",
          type: "error",
          duration: 3 * 1000,
        });
      }
    },
  },
};
</script>
<style scoped>
.out {
  position: absolute;
  top: 10px;
  right: 10px;
}

.checkinput {
  display: flex;
  margin: 30px;
}

.block {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  justify-content: center;
}

.countnum {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
