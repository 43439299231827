import request from '@/utils/request'


// {
//     "address": "string",
//     "code": "string",
//     "page": 0,
//     "page_size": 0
//   }
export function Search(data) {
    return request({
        url: 'admin/search',
        method: 'post',
        data
    })
}


export function getallCount() {
    return request({
        url: 'admin/allCount',
        method: 'get',
    })
}


export function getbycode(code) {
    return request({
        url: 'admin/CreationCount?code='+code,
        method: 'get',
    })
}

// {
//     "address": "string",
//   }
export function postExportrecord(data) {
    return request({
        url: 'admin/export-record',
        method: 'post',
        data
    })
}
