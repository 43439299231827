import Vue from 'vue'
import VueRouter from 'vue-router'


import Login from '../page/Login.vue'
import Index from '../page/Index.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/index',
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {path: '*', redirect: '/index'},

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to,from,next)=>{
  window.scrollTo(0,0)
})


export default router
