<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
import $ from 'jquery'

export default {
    name: 'App',
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    }

}

</script>
<style>
* {
    margin: 0;
    padding: 0;

}



</style>
