import request from '@/utils/request'

// {
//     "password": "string",
//     "username": "0x2w323423"
//   }
export function Login(data) {
    return request({
        url: 'admin/login',
        method: 'post',
        data
    })
}