/*
 * @Author: “mxmxmx” “2454023350@qq.com”
 * @Date: 2024-07-22 15:19:56
 * @LastEditors: “mxmxmx” “2454023350@qq.com”
 * @LastEditTime: 2024-07-22 22:52:18
 * @FilePath: /vue-horhead/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import jquery from "jquery";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
Vue.use(ElementUI);

// 设置语言
locale.use(lang)

Vue.prototype.$ = jquery;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
