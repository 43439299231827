<template>
    <div class="Login" v-loading.fullscreen.lock="loading">
        <div>
            <p>Account: </p>
            <el-input placeholder="Please enter your account" v-model="account" clearable></el-input>
        </div>
        <div>
            <p>Password: </p>
            <el-input placeholder="Please input a password" v-model="password" show-password></el-input>
        </div>
        <el-button @click="login">Sign in</el-button>
    </div>
</template>
<script>
import { Login } from "@/api/login";
import { setToken } from '@/utils/auth'

export default {
    name: 'Login',
    data() {
        return {
            account: '',
            password: '',
            loading: false,
        }
    },
    created() {

    },
    methods: {
        login() {
            if (this.account == '' || this.password == '') {
                return
            }
            this.loading = true;
            Login({
                "password": this.password,
                "username": this.account
            }).then(res => {
                this.loading = false;
                if (res.code == 0) {
                    setToken(res.data.accessToken)
                    this.$router.push('/index')
                }
            }).catch(res=>{
                this.loading = false;
            })

        }
    }
}
</script>
<style scoped>
.Login {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Login div {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.Login div p {
    width: 110px;
}
</style>